const branches = ["文賢", "崇善"];
const warehouseUnits = ["文賢", "崇善", "奇美", "總倉"];

const branchOptions = [
  // { text: "請選擇", value: "", disabled: true },
  { text: "文賢店", value: "文賢" },
  { text: "崇善店", value: "崇善" },
  { text: "奇美店", value: "奇美" },
];

const warehouseUnitOptions = [
  { text: "文賢店", value: "文賢" },
  { text: "崇善店", value: "崇善" },
  { text: "奇美店", value: "奇美" },
  { text: "總倉", value: "總倉" },
];

export { branches, warehouseUnits, branchOptions, warehouseUnitOptions };
