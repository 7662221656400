<template>
  <v-container fluid>
    <h3 class="page-title">貨款紀錄與管理</h3>

    <v-row>
      <v-col>
        <h4>篩選條件</h4>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" sm="4" md="2">
        <v-text-field
          label="關鍵字"
          placeholder="關鍵字(商品名稱、備註)"
          v-model.trim="filterQuery.keyword"
          outlined
          dense
          hide-details
          clearable
        >
        </v-text-field>
      </v-col>
      <v-col cols="6" sm="4" md="2">
        <v-autocomplete
          label="店點"
          v-model="filterQuery.branch"
          :items="branchOptions"
          outlined
          dense
          hide-details
          :clearable="$vuetify.breakpoint.smAndUp"
        >
        </v-autocomplete>
      </v-col>
      <v-col cols="6" sm="4" md="2">
        <v-select
          label="狀態"
          v-model="filterQuery.status"
          outlined
          dense
          hide-details
          :clearable="$vuetify.breakpoint.smAndUp"
          :items="[
            { text: '完成', value: 'finished' },
            { text: '作廢', value: 'canceled' },
            { text: '處理中', value: 'progress' },
          ]"
        >
        </v-select>
      </v-col>
    </v-row>
    <v-row v-if="filterData">
      <v-col cols="12">
        <v-data-table
          :headers="headers"
          :items="filterData"
          :mobile-breakpoint="0"
          item-key="_id"
          group-by="purchaseId"
          :footer-props="{
            itemsPerPageText: '每頁幾筆',
            itemsPerPageOptions: [10, 30, -1],
          }"
        >
          <template v-slot:group.header="{ items }">
            <td colspan="7">
              <div>{{ items[0].branch + "店＿＿" + items[0].createdAt }}</div>
            </td>
          </template>
          <template v-slot:item.status="{ item }">
            <v-chip
              v-if="item.status === 'progress'"
              class="ma-2"
              color="green"
              text-color="white"
              x-small
            >
              處理中
            </v-chip>
            <v-chip v-if="item.status === 'finished'" class="ma-2" x-small>
              已完成
            </v-chip>
            <v-chip
              v-if="item.status === 'canceled'"
              class="ma-2"
              color="error"
              x-small
              outlined
            >
              已作廢
            </v-chip>
          </template>
          <template v-slot:item.actions="{ item }">
            <v-btn small color="primary" @click="openDialog(item)">編輯</v-btn>
          </template>
          <!-- <template v-slot:expanded-item="{ headers, item }">
            <td :colspan="headers.length" class="pa-4">
              <v-row>
                <v-col cols="12" md="6" v-if="item.comment">
                  <h4 class="px-4 py-4">備註</h4>
                  <v-textarea
                    :value="item.comment"
                    rows="2"
                    row-height="25"
                    auto-grow
                    hide-details
                    readonly
                    class="px-4"
                  ></v-textarea>
                </v-col>
              </v-row>
            </td>
          </template> -->
        </v-data-table>
      </v-col>
    </v-row>

    <v-dialog v-model="open" v-if="open" persistent width="400">
      <v-card>
        <v-card-title>貨款狀態編輯</v-card-title>
        <v-card-text>
          <div class="mb-3">
            <v-text-field
              label="商品"
              outlined
              dense
              disabled
              hide-details
              :value="selectedLog?.product?.name"
            ></v-text-field>
          </div>
          <div class="mb-3">
            <v-text-field
              label="狀態"
              :value="statusMap[selectedLog.status]"
              outlined
              dense
              hide-details
              disabled
            ></v-text-field>
          </div>
          <div class="mb-3">
            <v-text-field
              label="付款金額"
              outlined
              dense
              disabled
              hide-details
              :value="selectedLog?.paid"
            ></v-text-field>
          </div>
          <div class="mb-3">
            <v-select
              label="更新狀態"
              v-model="payload.status"
              :items="statusOptions"
              outlined
              dense
              hide-details
            ></v-select>
          </div>
          <div class="mb-3">
            <v-text-field
              type="number"
              v-model.number="payload.amount"
              label="更新付款金額"
              outlined
              dense
              clearable
              hide-details
            ></v-text-field>
          </div>
          <div class="mb-3">
            <v-textarea
              v-model="payload.comment"
              label="備註"
              outlined
              dense
              hide-details
              clearable
              no-resize
            ></v-textarea>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-btn text class="bt-text-large" color="error" @click="clear">
            取消
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn text class="bt-text-large" color="primary" @click="update">
            確定
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import _ from "lodash";
import dayjs from "dayjs";
import util from "@/mixins/util";
import { warehouseUnitOptions } from "@/config/common.js";

export default {
  name: "PayableLog",
  mixins: [util],
  components: {},
  data: () => ({
    date: [],
    data: [],
    menu: false,
    branch: null,
    products: [],
    filterQuery: {
      branch: "",
      status: "",
      keyword: "",
    },
    branches: [],
    payload: {
      status: "",
      paid: "",
      comment: "",
    },
    selectedLog: null,
    open: false,
    warehouseUnitOptions: [
      { text: "請選擇", value: "", disabled: true },
      ...warehouseUnitOptions,
    ],
    statusMap: {
      finished: "完成",
      canceled: "作廢",
      progress: "處理中",
    },
    statusOptions: [
      { text: "完成", value: "finished" },
      { text: "作廢", value: "canceled" },
      { text: "處理中", value: "progress" },
    ],
    headers: [
      { text: "商品", value: "product.name", align: "center", sortable: false },
      { text: "採購量", value: "required", align: "center", sortable: false },
      // { text: "價格", value: "cost", align: "center", sortable: false },
      { text: "金額", value: "amount", align: "center", sortable: false },
      { text: "已付金額", value: "paid", align: "center", sortable: false },
      { text: "狀態", value: "status", align: "center", sortable: false },
      { text: "備註", value: "comment", align: "center", sortable: false },
      { text: "操作", value: "actions", align: "center", sortable: false },
    ],
  }),
  async created() {
    await this.load();
    // this.date[0] = dayjs().format("YYYY-MM-01");
    // this.date[1] = dayjs().endOf("month").format("YYYY-MM-DD");
  },
  computed: {
    formattedDate() {
      return this.date[0] && this.date[1]
        ? dayjs(this.date[0]).format("YYYY-MM-DD") +
            " ~ " +
            dayjs(this.date[1]).format("YYYY-MM-DD")
        : "";
    },
    filterData() {
      const filtered = this.data.filter((item) => {
        let result = true;
        if (this.filterQuery.branch) {
          result = result && item.branch === this.filterQuery.branch;
        }
        if (this.filterQuery.keyword) {
          result =
            result &&
            item.product.name
              .toUpperCase()
              .includes(this.filterQuery.keyword.toUpperCase());
        }
        if (this.filterQuery.status) {
          result =
            result &&
            (item.status === this.filterQuery.status ||
              item.comment === this.filterQuery.status);
        }
        return result;
      });
      return filtered;
      // return _.groupBy(filtered, "purchaseId");
    },
    branchOptions() {
      const branches = this.branches.map((branch) => {
        return { value: branch, text: branch + "店" };
      });
      return branches.length > 1
        ? [{ text: "全部", value: "" }, ...branches]
        : branches;
    },
  },
  methods: {
    async load() {
      await this.getPayable();
    },
    async getPayable() {
      if (this.$store.state.user.角色 === "店長") {
        //店長支援友愛，但僅能開放友愛權限
        const { data } = await this.axios.get(`/payable`, {
          params: { branch: "友愛" },
        });
        this.data = data;
        this.branches = _.uniqBy(data, "branch").map((item) => item.branch);
      } else {
        const { data } = await this.axios.get(`/payable`);
        this.data = data;
        this.branches = _.uniqBy(data, "branch").map((item) => item.branch);
      }
    },
    openDialog(item) {
      console.log(item);
      if (item) {
        this.selectedLog = item;
      }
      this.open = true;
    },
    clear() {
      this.open = false;
      this.selectedLog = null;
    },
    async update() {
      try {
        const process = [];
        if (this.payload.status) {
          process.push(
            this.axios.put(`/payable/${this.selectedLog._id}/update-status`, {
              status: this.payload.status,
            })
          );
        }
        if (this.payload.paid || this.payload.paid === 0) {
          process.push(
            this.axios.put(`/payable/${this.selectedLog._id}/update-paid`, {
              paid: this.payload.paid,
            })
          );
        }
        if (this.payload.comment) {
          process.push(
            this.axios.put(`/payable/${this.selectedLog._id}/update-comment`, {
              comment: this.payload.comment,
            })
          );
        }

        this.clear();
        await this.getPayable();
      } catch (error) {
        this.$toast.error("更新失敗");
      }
    },
    // async search() {
    //   if (!this.date) {
    //     return false;
    //   }

    //   this.$vloading.show();
    //   try {
    //     let { data } = await this.axios.get(`/payable`, {
    //       // params: {
    //       //   dateRange: this.date,
    //       // },
    //     });
    //     this.result = data.sort((a, b) => {
    //       const aTime = a.createdAt;
    //       const bTime = b.createdAt;
    //       if (dayjs(aTime).isAfter(dayjs(bTime))) {
    //         return -1;
    //       }
    //       if (dayjs(aTime).isBefore(dayjs(bTime))) {
    //         return 1;
    //       }
    //       return 0;
    //     });
    //   } catch (error) {
    //     this.$toast.error(error.message);
    //   }

    //   this.$vloading.hide();
    // },
  },
};
</script>

<style lang="scss" scoped></style>
